







































































import { activeCallStore, teamsStore } from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CallMemberAvatar from '@/components/Calls/CallMemberAvatar.vue'
import { CallEntity } from '@/store/modules/activeCall/models'

@Component({
  components: {
    CallMemberAvatar,
    IconMicOff: () => import('@/components/UI/icons/IconMicOff.vue'),
    IconVideoOff: () => import('@/components/UI/icons/IconVideoOff.vue'),
  },
})
export default class CallMemberItem extends Vue {
  @Prop({
    type: CallEntity,
    required: true,
  }) readonly entity!: CallEntity

  get isTalking (): boolean {
    const call = activeCallStore.state.activeCall
    if (!call) return false
    return call.talkingMembers.includes(this.entity.jid)
  }

  private openChat (): void {
    this.$router.push({
      name: 'Chat',
      params: {
        teamId: teamsStore.getters.currentTeam.uid,
        jid: this.entity.jid,
      },
    })
  }
}
